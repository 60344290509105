<template>
    <div
        class="white--text align-start px-4 pt-12 hero-background fill-height"
        :style="backgroundImg"
    >
        <v-container>
            <v-responsive
                class="d-flex validate-warranty"
                max-width="700"
                width="100%"
            >
                <base-heading title="CREATE A SERVICE REQUEST" />
                <base-body>
                    Input your serial below to get the process started.
                </base-body>

                <div
                    :class="
                        $vuetify.breakpoint.xsAndDown
                            ? 'd-flex flex-wrap flex-column align-start'
                            : 'd-flex flex-wrap'
                    "
                >
                    <validate-warranty
                        dark
                        @serialData="serialData = $event"
                        @keyup.enter="createRma"
                        @isValid="isValid = $event"
                    />
                    <base-btn
                        :block="$vuetify.breakpoint.xsAndDown"
                        :disabled="!serialData.serial"
                        data-cy="create-rma-btn"
                        dark
                        x-large
                        :class="$vuetify.breakpoint.smAndDown ? 'mb-3' : ''"
                        @click="createRma"
                    >
                        Create New RMA
                    </base-btn>
                </div>
                <transition name="fade">
                    <unit-card
                        v-if="serialData.serial"
                        ref="unitCard"
                        max-width="450"
                        :warranty-type="serialData.warrantyType"
                        :serial="serialData.serial"
                        :model="serialData.model"
                        :model-family="serialData.modelFamily"
                        :iw="serialData.iw"
                        :rma-exists="serialData.rmaExists"
                        :end-device-warranty="serialData.endDeviceWarranty"
                        :is-e-o-s="serialData.isEOS"
                    />
                    <!-- <v-card v-else min-height="168" color="transparent" flat /> -->
                </transition>
            </v-responsive>
        </v-container>
    </div>
</template>

<script>
import ValidateWarranty from '../../components/validate_warranty/validate_warranty'
import UnitCard from '../../components/unit_card/unit_card'
export default {
    name: `SectionHero`,

    components: {
        ValidateWarranty,
        UnitCard
    },

    data: () => ({ isValid: false, serialData: {} }),

    computed: {
        backgroundImg() {
            return {
                backgroundImage:
                    `linear-gradient(rgba(5, 11, 31, 0.8),rgba(5, 11, 31, 0.8)), url(` +
                    process.env.VUE_APP_PUBLIC_ASSETS_URL +
                    `/images/army-BG3.jpg` +
                    `)`
            }
        },
        maxHeight() {
            return (
                this.$vuetify.breakpoint.height -
                72 -
                this.$vuetify.application.top
            )
        },
        minHeight() {
            const height = this.$vuetify.breakpoint.mdAndUp ? `100vh` : `100vh`
            return `calc(${height} - ${this.$vuetify.application.top}px)`
        }
    },

    methods: {
        createRma() {
            if (this.serialData.serial) {
                this.$router.push({
                    name: `create-rma`,
                    params: {
                        serial: this.serialData ? this.serialData.serial : ``
                    }
                })
            }
        }
    }
}
</script>

<style scoped lang="scss">
.hero-background {
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>
